/* Loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.loader {
    border-top: 8px solid #F49668; /* Orange color */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}