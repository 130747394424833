.phone-verification input[type="text"],
.phone-verification input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    outline: none;
    font-size: 16px;
}

.phone-verification input[type="text"],
.phone-verification input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
}

.phone-verification input[type="text"]:focus,
.phone-verification input[type="email"]:focus {
    border-color: #4681f4;
    box-shadow: 0 0 5px rgba(70, 129, 244, 0.5);
}


.phone-verification .country-code {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px 0 0 10px;
    background-color: #f1f1f1;
    font-size: 12px;
}

.phone-verification .verify-button {
    background-color: #FF851A;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.verify-button.disabled {
    background-color: #ccc; /* Greyed out when disabled */
    cursor: not-allowed;
}

.phone-verification .verify-button.loading {
    background-color: #ccc;
    cursor: not-allowed;
}

.phone-verification .code-input-container {
    margin-top: 20px;
}

.phone-verification .code-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}

.phone-verification .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}

.phone-verification .success-message h3 {
    color: black;
    font-size: 24px;
    margin-bottom: 20px;
}

.phone-verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}
.phone-verification .name-input,
.phone-verification .email-input {
    width: calc(50% - 10px);
    margin-right: 10px;
}

.phone-verification .email-input {
    margin-right: 0;
}
