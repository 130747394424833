/* Footer.css */

.footer {
    background-color: #343a40; /* Dark grey background */
    color: #ffffff; /* White text */
    padding: 1rem 0;
    text-align: center;
    width: 100%;  position: fixed; /* Fix the footer at the bottom */
    bottom: 0; /* Stick to the bottom */
    z-index: 1000;
    height: 40px !important;
}


.footer-phone {
    font-size: 16px;
    margin-bottom: 0.5rem;
}

.footer-copyright {
    font-size: 14px;
    margin: 0;
}

.footer-copyright a {
    color: #4681f4; /* Matching the MoveFlair logo color */
    text-decoration: none;
}

.footer-copyright a:hover {
    text-decoration: underline;
}