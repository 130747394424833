/* src/styles/MoveFlairModal.css */
.moveflair-modal .modal-dialog {
  width: 100%; /* Full width */
  max-width: 80vw; /* Max width for larger screens */
}

.moveflair-modal .modal-content {
  height: 80vh; /* Fixed height */
  overflow: auto; /* Enable scrolling if content overflows */
}

@media (min-width: 768px) {
  .moveflair-modal .modal-dialog {
    max-width: 70vw; /* Adjust width for medium to large screens */
  }
}

@media (min-width: 1200px) {
  .moveflair-modal .modal-dialog {
    max-width: 60vw; /* Adjust width for extra large screens */
  }
}

.moveflair-modal .modal-header,
.moveflair-modal .modal-body,
.moveflair-modal .modal-footer {
  padding: 20px;
}

.moveflair-modal .modal-title {
  font-size: 24px;
  font-weight: bold;
}

.moveflair-modal .modal-footer {
  display: flex;
  justify-content: space-between;
}
