/* Home.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'); /* Import Poppins font */

body {
    font-family: 'Poppins', sans-serif;
}

.home-container {
    padding: 40px;
    text-align: center;
}

.home-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
}

.template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Increase the min size of each card */
    gap: 30px; /* Increase the gap between cards */
    justify-items: center;
    margin-bottom: 20px; /* Space for the description div */
}

.template-card {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 15px; /* More rounded corners */
    padding: 30px; /* Increase padding inside cards */
    max-width: 350px; /* Make the cards wider */
    width: 100%;
    transition: transform 0.3s, box-shadow 0.3s; /* Add box-shadow transition */
    position: relative;
    cursor: pointer; /* Add a pointer cursor to indicate clickable area */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.template-card:hover {
    transform: translateY(-10px); /* Increase the lift on hover */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
    cursor: pointer; /* Add a pointer cursor to indicate clickable area */

}

.template-image-wrapper {
    width: 100%;
    height: 200px; /* Make the images taller */
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 20px; /* Increase space below image */
}

.template-image-wrapper:hover .template-image {
    transform: scale(1.05); /* Slight zoom on hover */
    transition: transform 0.3s ease;
}

.template-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.template-info h3 {
    font-size: 20px; /* Larger font for title */
    font-weight: bold; /* Make the text bold */
    margin-bottom: 10px;
    color: #333; /* Darker text color for better contrast */
}

.service-description {
    background-color: #faf3e0; /* Yellow background */
    color: #333333; /* Black text for readability */
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .home-container {
        padding: 20px;
    }

    .template-card {
        padding: 20px;
        max-width: 100%; /* Allow the card to be as wide as the screen */
        width: 100%;
    }

    .template-image-wrapper {
        height: 150px;
    }
    .template-grid {
        grid-template-columns: 1fr; /* Full width for each card on mobile */
    }

}

