header {
    background: #fafafa;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
}

nav {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.nav-left {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 40px;
    margin: 0 0 0 20px;
    border-color: white;
    border-radius: 10px;
}

.logo-text {
    font-size: 2rem;
    font-family: 'Playfair Display', serif;
    color: black;
    margin-right: 30px;

}
