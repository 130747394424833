/* Main.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Avenir:wght@400;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Avenir', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Define colors for each word */
.color-0 { color: #FF5733; } /* Red */
.color-1 { color: #33FF57; } /* Green */
.color-2 { color: #3357FF; } /* Blue */
.color-3 { color: #FF33FF; } /* Pink */
.color-4 { color: #FF8C66; } /* Orange */

/* Animation for flipping effect */
.flipping-word {
    display: inline-block;
    animation: flip 4s ease-in-out;
    font-family: 'Montserrat', sans-serif;
}

@keyframes flip {
    0% {
        transform: rotateX(0);
        opacity: 1;
    }
    50% {
        transform: rotateX(90deg);
        opacity: 0;
    }
    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}

.intro {
    display: flex;
    padding: 4rem 2rem 4rem 3rem; /* Add padding-left to the intro section */
    gap: 2rem; /* Adjust the gap as needed */
}

/* Mobile Optimizations */
@media only screen and (max-width: 768px) {
    .intro {
        flex-direction: column; /* Stack elements vertically on small screens */
        padding: 2rem 1rem 2rem 2rem; /* Reduced padding for mobile, with left padding */
    }

    .intro-text h1 {
        font-size: 2.5rem; /* Slightly reduce heading size */
        margin-bottom: 1rem; /* Reduced margin below heading */
    }

    .intro-text p {
        font-size: 1rem; /* Slightly smaller font size */
        margin-bottom: 1rem; /* Reduced margin below paragraph */
    }

    .form-container {
        width: 100% !important; /* Ensure form container takes full width */
        padding: 0 1rem 0 2rem; /* Add padding inside the form */
    }

    .input-icon {
        margin-bottom: 0.5rem; /* Reduce spacing between inputs */
    }

    .primary-button {
        width: 100%; /* Make the button stretch across the container */
        padding: 0.75rem 1rem; /* Reduced padding inside the button */
        margin-top: 0.75rem; /* Reduced margin above the button */
    }

    .intro-graphic {
        width: 100%; /* Ensure graphic takes full width */
        margin-top: 1rem; /* Add some space above the image grid */
    }

    .intro-graphic img {
        max-width: 100%; /* Full width on smaller screens */
        margin: 0 auto; /* Center the image */
    }
}

@media only screen and (max-width: 480px) {
    .intro {
        padding: 1.5rem 0.5rem 1.5rem 1rem; /* Further reduced padding for very small screens, with left padding */
    }

    .intro-text h1 {
        font-size: 2rem; /* Further reduce heading size */
    }

    .form-container {
        padding: 0 0.5rem 0 1rem; /* Further reduce padding inside the form, with left padding */
    }

    .primary-button {
        margin-top: 0.5rem; /* Further reduce the margin above the button */
    }
}

/* General Styles */
.intro-graphic {
    width: 100%; /* Ensures the container takes full width of its parent */
}

.intro-text, .intro-graphic {
    flex: 2; /* Both children will take up equal space */
}

.intro-graphic img {
    width: 100%; /* Ensures the image is responsive */
    max-width: 100%; /* Allows the image to expand fully on smaller screens */
    height: auto; /* Maintain aspect ratio */
    border-radius: 15px; /* Rounded corners */
    display: block; /* Removes extra space below the image */
    margin: 0 auto; /* Centers the image horizontally */
}

.intro-text h1 {
    font-size: 3rem;
    color: #000;
}

.intro-text p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

.search input {
    padding: 1.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Adjust the space between input and button */
    width: 80%;
}

.search button {
    padding: 0.5rem 1rem;
    background: #000;
    color: #fff;
    border: none;
    cursor: pointer;
}

.input-icon {
    padding: 10px 20px;
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 20px; /* Rounded borders */
    width: 100%;
    max-width: 100%;
    outline: none;
    font-size: 16px; /* Adjust as needed */
}

@media (max-width: 768px) {
    .input-icon {
        border-width: 1.5px; /* Thinner border for smaller screens */
    }
}

@media (max-width: 480px) {
    .input-icon {
        border-width: 1px; /* Even thinner border for very small screens */
    }
}

.input-icon input {
    width: 100%; /* Ensures the input takes up all available space */
    border: none;
    outline: none;
    padding: 8px 0; /* Adjust padding to match design, minimal horizontal padding */
    margin: 0; /* Removes any default margin */
}

/* Placeholder color */
.input-icon input::placeholder {
    color: grey;
}

.autocomplete-results {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; /* Full width by default */
    max-width: 400px; /* Maximum width for larger screens */
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    border-radius: 0 0 8px 8px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
}

.autocomplete-results li {
    padding: 8px 12px;
    cursor: pointer;
}

.autocomplete-results li:hover {
    background-color: #f8f8f8;
}

@media (min-width: 768px) {
    .autocomplete-results {
        width: 100%; /* Full width of the input field */
    }
}

@media (max-width: 480px) {
    .autocomplete-results {
        max-width: 95%; /* Slightly narrower for very small screens */
        left: 50%; /* Center the dropdown */
        transform: translateX(-50%); /* Center alignment */
    }
}