/* src/styles/NotFound.css */
.not-found-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    background-color: #f5f5f5; /* Light grey background */
}

.logo {
    width: 150px;
    height: auto;
    margin: 0 auto;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

h1 {
    font-size: 36px;
    margin: 20px 0;
    color: #333;
}


.btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4681f4; /* Button color matching your brand */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.btn:hover {
    background-color: #356ac9; /* Darker shade on hover */
}

