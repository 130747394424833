.review-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
  }
  
  .inventory-item {
    margin-top: 10px;
  }
.promo-container {
    display: flex;
    gap: 10px;
    justify-content: center; /* Center the form */
    margin-top: 10px;
}

.promo-input {
    width: 200px; /* Reduce the width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 30px; /* Make the input very rounded */
    text-align: center; /* Center text inside the input */
}

.apply-button {
    background-color: #28A745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.apply-button:disabled {
    background-color: #ccc; /* Greyed out when disabled */
    cursor: not-allowed;
}

.promo-applied-message {
    color: #28A745; /* Green color to indicate success */
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}