/* CitiesModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}
/* CitiesModal.css */
.modal-overlay .modal-content {
    background-color: #fff !important; /* Force white background */
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}
.close-button {
    background-color: #dd7044; /* Customize this as needed */
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
}

.close-button:hover {
    background-color: #c06339; /* Darker hover color */
}