/* Booking.css */

.booking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa; /* Light grey background */
}

.booking-content {
    text-align: center;
    background-color: #ffffff; /* White background for the content */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 400px;
    width: 100%;
}

.booking-gif {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
}

.booking-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #343a40; /* Dark grey for text */
}

.booking-text {
    font-size: 16px;
    color: #6c757d; /* Medium grey for text */
}

.booking-error {
    font-size: 16px;
    color: #dc3545; /* Red for error text */
    margin-top: 1rem;
}

.booking-link {
    color: #FF851A; /* Use the MoveFlair logo color */
    text-decoration: none;
    font-weight: bold;
}

.booking-link:hover {
    text-decoration: underline;
}