.primary-button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #f49668; /* Soft coral color */
    color: #fff;
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Modern, clean font */
    font-size: 21px; /* Slightly larger for better readability */
    font-weight: bold; /* Make the text stand out */
    letter-spacing: 1px; /* Slight spacing between letters */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.primary-button:hover {
    background-color: #dd7044; /* Slightly darker on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.05); /* Slight zoom effect */
}

.secondary-button {
    padding: 10px 20px;
    border: 2px solid #f49668; /* Soft coral border to match the primary button */
    border-radius: 20px;
    background-color: transparent; /* Transparent background */
    color: #f49668; /* Soft coral text color */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Modern, clean font */
    font-size: 18px; /* Consistent with primary button */
    font-weight: bold; /* Consistent with primary button */
    letter-spacing: 1px; /* Slight spacing between letters */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.secondary-button:hover {
    background-color: #f49668; /* Soft coral background on hover */
    color: #fff; /* White text on hover */
    transform: scale(1.05); /* Slight zoom effect on hover */
}
.cta-button {
    padding: 12px 24px;
    border: none;

    background-color: #23F18B; /* Logo color for strong visual impact */
    color: #fff;
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Modern, clean font */
    font-weight: bold; /* Make the text stand out */
    letter-spacing: 1.2px; /* Slightly more spacing for emphasis */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.cta-button:hover {
    background-color: #1CD779; /* Slightly darker shade of blue on hover */
    transform: scale(1.07); /* More pronounced zoom effect on hover */
}

.primary-button, .secondary-button, .cta-button {
    outline: none; /* Remove default browser outline */
}

.primary-button:focus, .secondary-button:focus, .cta-button:focus {
    outline: 2px solid #4681f4; /* Add a custom focus outline for better accessibility */
    outline-offset: 2px; /* Offset the outline slightly */
}
